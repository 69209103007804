import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { Antrag } from './pages/Antrag';
import { Attachments } from './pages/Attachments';
import { Dashboard } from './pages/Dashboard';
import { Loan } from './pages/Loan';
import { MemberAccount } from './pages/MemberAccount';
import { PersonalData } from './pages/PersonalData';
import { Shares } from './pages/Shares';
import { Error } from './pages/error/Error';
import { NotMemberError } from './pages/error/NotMemberError';
import DataService from './services/DataService';
import { ServiceUnavailableError } from './pages/error/ServiceUnavailableError';
import { Footer } from './components/Footer';
import { SwitchMember } from './pages/SwitchMember';
import { EditPersonalData } from './pages/edit/EditPersonalData';
import { ProjectsIframe } from './pages/iframes/ProjectsIframe';
import { NewLoan } from './pages/NewLoan';
import { EditBankAccount } from './pages/edit/EditBankAccount';
import { CreateBankAccount } from './pages/edit/CreateBankAccount';
import { TaskPage } from './pages/tasks/TaskPage';
import { EditLoanApplication } from './pages/edit/EditLoanApplication';

const theme = createTheme({
  palette: {
    primary: {
      main: '#64a70b',
    },
    secondary: {
      main: '#5b4261',
    },
  },
  typography: {
    fontFamily: "Source Sans Pro,sans-serif"
  }
});

const currentSubdomain = window.location.hostname.split('.')[0];
const isAppSubdomain = currentSubdomain === 'app';

//Config object recieved from server
declare global {
  type Config = {
    cooperativeName: string,
    nominalShareValue: number,
    maxAllowedHeldShares: number,
    loanMinSum: number,
    sharePurchaseFeeRate: number,
    bank_owner: string,
    bank_iban: string,
    bank_name: string,
    bank_bic: string,
    creditorIdentificationNumber: string,
    statuteUrl: string,
    email: string,
    address_street: string,
    address_extra: string,
    address_postalcode: string,
    address_city: string,
    address_country: string,
    dataProtectionRegulationUrl: string,
    legalNoticeUrl: string
  }
}

function App() {
  //Mobile-Variable
  const [isMobile, setMobile] = useState<boolean>(false);

  //Set default values so undefined error is prevented, there is probably a better solution to do this
  const [config, setConfig] = useState<Config>({
    cooperativeName: "Bürgerenergie",
    nominalShareValue: 1,
    maxAllowedHeldShares: 1,
    loanMinSum: 1000,
    sharePurchaseFeeRate: 1,
    bank_owner: "",
    bank_iban: "",
    bank_name: "",
    bank_bic: "",
    creditorIdentificationNumber: "",
    statuteUrl: "",
    email: "",
    address_street: "",
    address_extra: "",
    address_postalcode: "",
    address_city: "",
    address_country: "",
    dataProtectionRegulationUrl: "",
    legalNoticeUrl: ""
  });

  function handleWindowSizeChange() {
    setMobile(window.innerWidth < 1280)
  }

  useEffect(() => {
    //Send alert if site key is not set (can happen very easily)
    if (process.env.6Lf8gPkpAAAAAM6RBzLxIGc2zb-ayqkEfXG2bA25 === undefined) {
      alert("ERROR: Message for admin: The google recaptcha site key is not set. In order to send member applications you need to set the site key. (Look at dockerfile)")
    }

    //Request to fetch configurable data from backend service (backend jmix portal)
    DataService.getConfig(setConfig);

    //Needed for beg dachauerland to set proper iframe size
    function sendSize() {
      window.parent.postMessage({
        height: document.body.scrollHeight + 90,
        width: document.body.scrollWidth,
        type: 'iframeSize'
      }, 'https://buergerenergie-dachauerland.de');
    }

    if (window.location.hostname.includes('buergerenergie-dachauerland')) {
      sendSize();
    }
    window.addEventListener('resize', sendSize);

    //Sets isMobile on resize    
    handleWindowSizeChange();

    //Even if WindowSizeCheck did not detect phone, this can
    const userAgentIsMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (userAgentIsMobile) {
      setMobile(true);
    }
    
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
    
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/*Routes with Navigation */}
          <Route element={<>
            <Navigation isMobile={isMobile} config={config!}/>
            <Outlet />
            <Footer config={config!}/>
          </>}>
            {/**Portal pages */}
            {isAppSubdomain ? (<></>) : (<Route path='/' element={<Dashboard isMobile={isMobile} config={config!} />} />)}
            <Route path='/personaldata' element={<PersonalData isMobile={isMobile} config={config!} />} />
            <Route path='/memberaccount' element={<MemberAccount isMobile={isMobile} config={config!} />} />
            <Route path='/shares' element={<Shares isMobile={isMobile} config={config!} />} />
            <Route path='/projectloans' element={<Loan isMobile={isMobile} config={config!} />} />
            <Route path='/projectloans/new/:id' element={<NewLoan isMobile={isMobile} config={config!}/>} />
            <Route path='/tasks/task/:id' element={<TaskPage isMobile={isMobile} config={config!}/>} />
            <Route path='/attachments' element={<Attachments isMobile={isMobile} config={config!} />} />
            <Route path='/switchmember' element={<SwitchMember isMobile={isMobile} config={config!} />} />
            {/**Portal edit pages */}
            <Route path='/personaldata/edit' element={<EditPersonalData isMobile={isMobile} config={config!} />} />
            <Route path='/bankaccount/edit/:id' element={<EditBankAccount isMobile={isMobile} config={config!} />} />
            <Route path='/bankaccount/create' element={<CreateBankAccount isMobile={isMobile} config={config!} />} />
            <Route path='/projectloans/edit/:id' element={<EditLoanApplication isMobile={isMobile} config={config!} />} />
            {/**Errors */}
            <Route path='/notmembererror' element={<NotMemberError config={config!} />} />
            <Route path='/error/:status' element={<Error />} />
            <Route path='/*' element={<Error />} />
          </Route>
          {/*Routes without Navigation */}
          {isAppSubdomain ? (<Route path='/' element={<Antrag isMobile={isMobile} config={config!} />} />) : (<Route path='/mitgliedsantrag' element={<Antrag isMobile={isMobile} config={config!} />} />)}
          <Route path='/iframe/projects' element={<ProjectsIframe isMobile={isMobile} config={config!} inPortal={false} />} />
          <Route path='/serviceunavailable/:path' element={<ServiceUnavailableError isMobile={isMobile}/>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
